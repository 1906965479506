import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { escapeEmailAddress } from "../components/mailto-link"
import PageHeading from "../components/page-heading"

const PrivacyBotPage = () => {
  const data = useStaticQuery(graphql`
    query PrivacyBotQuery {
      site {
        siteMetadata {
          contact {
            email
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Datenschutzerklärung" />
      <PageHeading heading="Datenschutzerklärung" />
      <section>
        <Container>
          <Row>
            <Col className="col-12 mb-4">
              <h2>
                Wer ist verantwortlich für die Datenerhebung und Verarbeitung?
              </h2>
              <p>
                Der BürgerBot ist ein Angebot der{" "}
                <a href="https://www.viind.com">viind GmbH</a>. Für Fragen zur
                Datenerhebung und -verarbeitung sowie Anfragen gemäß DSGVO
                wenden Sie sich bitte an (Datenschutzbeauftragter):
              </p>
              <p>
                viind GmbH
                <br />
                Dettelbachergasse 9<br />
                97070 Würzburg
                <br />
                <br />
                E-Mail:{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: escapeEmailAddress(
                      data.site.siteMetadata.contact.email
                    ),
                  }}
                />
                <br />
                Telefon: 0931 73040399
              </p>
              <h2>
                Welche Daten erheben wir, wie und warum verarbeiten wir Ihre
                Daten?
                <br />
                Wie lange werden Ihre Daten gespeichert?
              </h2>
              <p>
                Wir erheben Ihre Daten nur dann, wenn Sie aktiv über einen von
                Ihnen gewählten Kanal mit uns in Kontakt treten.
              </p>
              <p>
                Sofern Sie mit uns in Kontakt treten, erheben wir folgende
                Daten:
              </p>
              <ul>
                <li>
                  Von Ihnen gesendete Nachrichten (z. B. in Form von Text, Fotos
                  oder Standortangaben)
                </li>
                <li>
                  <i>Bei Nutzung des Webchats:</i> Technische Merkmale
                  (IP-Adresse, genutzter Browser und Betriebssystem,
                  Zeitstempel)
                </li>
                <li>
                  <i>Bei Nutzung von Facebook Messenger und Telegram:</i>{" "}
                  Nutzer-ID
                </li>
                <li>
                  <i>Bei Nutzung von WhatsApp:</i> Rufnummer
                </li>
              </ul>
              <p>
                Diese Daten werden benötigt, um Ihre Anfragen bearbeiten und
                Ihnen ggf. eine Antwort zustellen zu können. Die Bearbeitung
                Ihrer Anfragen erfolgt durch Mitarbeiterinnen und Mitarbeiter
                der jeweils kontaktierten Stadt. Ihre Nutzer-ID bzw. Ihre
                Rufnummer wird nur für den Zweck der Antwort zwischengespeichert
                und ist für die Mitarbeiterinnen und Mitarbeiter der jeweils
                kontaktierten Stadt nicht einsehbar. Ferner können wir Ihre
                Daten zur internen Evaluation unseres Dienstes nutzen (z. B.
                Fehlersuche oder Erstellung von Statistiken).
              </p>
              <p>
                Wir bitten Sie ferner, keine Informationen zu übermitteln, die
                die Rechte von Dritten verletzen (z. B. Fotos mit Personen).
                Derartige Informationen werden nach Identifizierung unsererseits
                gelöscht.
              </p>
              <p>
                Soweit Ihre Daten elektronisch verarbeitet werden, erfolgt der
                technische Betrieb unserer Datenverarbeitungssysteme durch
                unsere Auftragsverarbeiter (Hetzner Online GmbH, netcup GmbH,
                IONOS SE) in ISO 27001-zertifizierten Rechenzentren.
              </p>
              <p>
                Sofern Sie uns über WhatsApp kontaktieren, arbeiten wir im
                Rahmen der WhatsApp Business API mit dem Dienstleister Twilio
                Germany GmbH zusammen. Der Dienstleister ermöglicht den Versand
                und Empfang von Nachrichten über WhatsApp und verarbeitet die
                Daten hierfür im Rahmen einer Auftragsdatenverarbeitung.
              </p>
              <p>Die maximale Speicherdauer beträgt ein Jahr.</p>
              <p>
                Für die von Ihnen verwendeten Messenger-Dienste, die Sie zur
                Kontaktaufnahme verwenden, gelten separate
                Datenschutzbestimmungen. Bitte informieren Sie sich bzgl.
                Datenverarbeitung und Speicherfristen bei den betreffenden
                Anbietern:
              </p>
              <p>
                Facebook Messenger – Facebook Ireland Ltd. –
                https://www.facebook.com/policy.php
                <br />
                Telegram – Telegram UK Holdings Ltd. –
                https://telegram.org/privacy
                <br />
                WhatsApp – WhatsApp Ireland Ltd. –
                https://www.whatsapp.com/legal/privacy-policy-eea
              </p>
              <h2>Rechtsgrundlage der Datenverarbeitung</h2>
              <p>
                Die Rechtsgrundlage für die Verarbeitung Ihrer Daten ergibt
                sich, soweit nichts anderes angegeben ist, aus Art. 4 Abs. 1 des
                Bayerischen Datenschutzgesetzes (BayDSG) in Verbindung mit Art.
                6 Abs. 1 Buchstabe e der Datenschutzgrundverordnung (DSGVO).
                Demnach ist es uns erlaubt, die zur Erfüllung einer uns
                obliegenden Aufgabe erforderlichen Daten zu verarbeiten. Soweit
                Sie in eine Verarbeitung eingewilligt haben, stützt sich die
                Datenverarbeitung auf Art. 6 Abs. 1 Buchstabe a DSGVO.
              </p>
              <h2>Werden Daten weitergegeben?</h2>
              <p>
                Eine Datenweitergabe abseits der genannten Verarbeitungszwecke
                findet nicht statt.
              </p>
              <h2>Welche Rechte haben Sie?</h2>
              <p>
                Soweit wir von Ihnen personenbezogene Daten verarbeiten, stehen
                Ihnen als Betroffener nachfolgende Rechte zu:
              </p>
              <ul>
                <li>
                  Werden Ihre personenbezogenen Daten verarbeitet, so haben Sie
                  das Recht, Auskunft über die zu Ihrer Person gespeicherten
                  Daten zu erhalten (Art. 15 DSGVO).
                </li>
                <li>
                  Sollten unrichtige personenbezogene Daten verarbeitet werden,
                  steht Ihnen ein Recht auf Berichtigung zu (Art. 16 DSGVO).
                </li>
                <li>
                  Liegen die gesetzlichen Voraussetzungen vor, so können Sie die
                  Löschung oder Einschränkung der Verarbeitung verlangen, sowie
                  Widerspruch gegen die Verarbeitung einlegen (Art. 17, 18 und
                  21 DSGVO).
                </li>
                <li>
                  Wenn Sie in die Verarbeitung eingewilligt haben oder ein
                  Vertrag zur Datenverarbeitung besteht und die
                  Datenverarbeitung mithilfe automatisierter Verfahren
                  durchgeführt wird, steht Ihnen gegebenenfalls ein Recht auf
                  Datenübertragbarkeit zu (Art. 20 DSGVO).
                </li>
                <li>
                  Falls Sie in die Verarbeitung eingewilligt haben und die
                  Verarbeitung auf dieser Einwilligung beruht, können Sie die
                  Einwilligung jederzeit für die Zukunft widerrufen. Die
                  Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
                  erfolgten Datenverarbeitung wird durch diesen nicht berührt.
                </li>
              </ul>
              <p>
                Weiterhin besteht ein Beschwerderecht beim Bayerischen
                Landesbeauftragten für den Datenschutz. Diesen können Sie unter
                folgenden Kontaktdaten erreichen:
              </p>
              <p>
                Postanschrift: Postfach 22 12 19, 80502 München <br />
                Adresse: Wagmüllerstraße 18, 80538 München <br />
                Telefon: 089 212672-0 <br />
                Telefax: 089 212672-50 <br />
                E-Mail: poststelle@datenschutz-bayern.de
                <br />
                Internet: https://www.datenschutz-bayern.de/
              </p>
              <p>Stand: 23.02.2022</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default PrivacyBotPage
